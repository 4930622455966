import React from 'react';

import { OutboundLink } from 'gatsby-plugin-google-analytics';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

const EquipmentLayout = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 960px) {
    border-top: solid 1px #e0e0e0;
    border-left: solid 1px #e0e0e0;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const Equipment = styled.a`
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 960px) {
    padding-top: 10px;
    border: solid 1px #e0e0e0;
    flex: 0 0 50%;
    border-top: 0;
    border-left: 0;
    height: 600px;
    width: 306px;
  }
  @media only screen and (min-width: 1024px) {
    flex: 0 0 33.3333%;
    height: 600px;
    width: 306px;
  }
  a {
    flex-shrink: 0;
    width: 100%;
    height: 40px;
    border-width: 0;
    background-color: #ee5a5a;
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 15px;
    font-weight: 700;
    border-radius: 6px;
    padding: 10px;
  }
  picture {
    flex-shrink: 0;
  }
  p {
    font-size: 14px;
  }
`;

class EquipmentIndex extends React.Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const beginnerPosts = posts.filter((post) => {
      return post.node.frontmatter.group == 'basic';
    });
    const advancedPost = posts.filter((post) => {
      return post.node.frontmatter.group == 'advanced';
    });
    const remainingPost = posts.filter((post) => {
      return (
        post.node.frontmatter.group != 'advanced' &&
        post.node.frontmatter.group != 'basic'
      );
    });
    const { amazonTag } = data.site.siteMetadata;
    return (
      <Layout location={this.props.location} title="My Kitchen Kit">
        <SEO
          title="My Kitchen Kit"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>My Kitchen Kit</h1>
        <p>
          This is the gear I use and recommend to other chefs to recreate the
          recipes on my blog. Click on any of the recommendations to get my tips
          on caring for these items, and the various recipes that I use them
          for.
        </p>
        <hr />
        <h2>Basics</h2>
        <p>
          These are the products that I would recommend to anyone, including a
          beginner chef. For everything else like knives you should use the
          options you have readily available cheaply until you can afford one of
          the advanced or upgrade picks.
        </p>
        <EquipmentLayout>
          {beginnerPosts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            const link =
              'https://www.amazon.com/dp/' +
              node.frontmatter.recommendation.asin +
              '?tag=' +
              amazonTag;
            return (
              <Equipment
                key={node.fields.slug}
                href={'/' + node.fields.path + node.fields.slug}
              >
                {node.frontmatter.recommendation.image && (
                  <Img
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={
                      node.frontmatter.recommendation.image.childImageSharp
                        .fluid
                    }
                  />
                )}
                <h3>{title}</h3>
                {node.frontmatter.recommendation.name && (
                  <h4>{node.frontmatter.recommendation.name}</h4>
                )}
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                <OutboundLink href={link} target="_blank">
                  Buy on Amazon
                </OutboundLink>
              </Equipment>
            );
          })}
        </EquipmentLayout>
        <h2>Advanced</h2>
        <p>
          These are the products that I recommend to people tring to get more
          involved in a home cooking practice, but might be too extravagant or
          might require a very specific type of upkeep that might be a lot for
          someone just getting started.
        </p>
        <EquipmentLayout>
          {advancedPost.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            const link =
              'https://www.amazon.com/dp/' +
              node.frontmatter.recommendation.asin +
              '?tag=' +
              amazonTag;
            return (
              <Equipment
                key={node.fields.slug}
                href={'/' + node.fields.path + node.fields.slug}
              >
                {node.frontmatter.recommendation.image && (
                  <Img
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={
                      node.frontmatter.recommendation.image.childImageSharp
                        .fluid
                    }
                  />
                )}
                <h3>{title}</h3>
                {node.frontmatter.recommendation.name && (
                  <h4>{node.frontmatter.recommendation.name}</h4>
                )}
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                <a href={link} target="_blank">
                  Buy on Amazon
                </a>
              </Equipment>
            );
          })}
        </EquipmentLayout>
        <h2>Specialty</h2>
        <p>
          These are the products that I love to use that may only apply to cooks
          for specific niche dishes.
        </p>
        <EquipmentLayout>
          {remainingPost.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug;
            const link =
              'https://www.amazon.com/dp/' +
              node.frontmatter.recommendation.asin +
              '?tag=' +
              amazonTag;
            return (
              <Equipment
                key={node.fields.slug}
                href={'/' + node.fields.path + node.fields.slug}
              >
                {node.frontmatter.recommendation.image && (
                  <Img
                    imgStyle={{ objectFit: 'contain' }}
                    fluid={
                      node.frontmatter.recommendation.image.childImageSharp
                        .fluid
                    }
                  />
                )}
                <h3>{title}</h3>
                {node.frontmatter.recommendation.name && (
                  <h4>{node.frontmatter.recommendation.name}</h4>
                )}
                <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                <a href={link} target="_blank">
                  Buy on Amazon
                </a>
              </Equipment>
            );
          })}
        </EquipmentLayout>
      </Layout>
    );
  }
}

export default EquipmentIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        amazonTag
      }
    }
    allMarkdownRemark(
      sort: {
        fields: [frontmatter___recommendation___price, frontmatter___date]
        order: DESC
      }
      filter: { fields: { path: { eq: "equipment" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            group
            recommendation {
              name
              asin
              price
              image {
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
